<template>
  <div class="container-fluid">
    <div class="card shadow-lg mt-3">
      <div class="card-body p-3">
        <div class="row gx-4">
          <div class="col-auto my-auto">
            <div class="h-100">
              <h5 class="mb-1" v-if="this.idCommessa">
                Modifica la commessa n. {{ this.commessa.number }}
              </h5>
              <h5 class="mb-1" v-else>Nuova commessa</h5>
            </div>
          </div>
          <div class="mx-auto mt-3 col-lg-2 col-md-6 my-sm-auto ms-sm-auto me-sm-0">
            <div class="nav-wrapper position-relative end-0">
              <ul class="p-1 bg-transparent nav nav-pills nav-fill" role="tablist">
                <li class="nav-item d-flex justify-content-end">
                  <dashboard-link></dashboard-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <!-- Nav tabs -->
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation" :class="{ active: this.tab === 1 }">
                <button class="nav-link" id="head-tab" data-bs-toggle="tab" data-bs-target="#head" type="button"
                  role="tab" aria-controls="head" aria-selected="true" :class="{ active: this.tab === 1 }"
                  @click="this.tab = 1">
                  Testata
                </button>
              </li>
              <li class="nav-item" role="presentation" :class="{ active: this.tab === 2 }">
                <button class="nav-link" id="lista-tubi-tab" data-bs-toggle="tab" data-bs-target="#lista-tubi"
                  type="button" role="tab" aria-controls="lista-tubi" aria-selected="false"
                  :class="{ active: this.tab === 2 }" @click="this.tab = 2">
                  Lista Tubi
                </button>
              </li>
              <li class="nav-item" role="presentation" :class="{ active: this.tab === 3 }"
                v-if="!this.commessa.closed || this.user.roles !== 'operatore'">
                <button class="nav-link" id="tubo-tab" data-bs-toggle="tab" data-bs-target="#tubo" type="button"
                  role="tab" aria-controls="tubo" aria-selected="false" :class="{ active: this.tab === 3 }"
                  @click="this.tab = 3">
                  <span v-if="this.tube._id">Modifica Tubo {{ this.commessa.number }}/{{
                    this.tube.tube_number
                    }}</span>
                  <span v-else>Aggiungi Tubo</span>
                </button>
              </li>
            </ul>

            <!-- Tab panes -->
            <div class="tab-content">
              <div class="tab-pane" id="head" role="tabpanel" aria-labelledby="home-tab"
                :class="{ active: this.tab === 1 }">
                <vee-form @submit="saveCommessa" :validation-schema="this.headSchema">
                  <div class="py-4 container-fluid">
                    <div class="row">
                      <div class="col-md-12">
                        <argon-alert color="danger" v-if="show_alert">{{ alert_msg }}
                        </argon-alert>
                        <argon-alert color="success" v-if="show_success">{{ alert_msg }}
                        </argon-alert>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <a @click.prevent="this.pdfCommessa" class="btn btn-primary" v-if="
                              !this.commessa.closed && this.tubes.length > 0">Genera PDF</a>
                          <a @click.prevent="this.pdfDownloadCommessa" class="btn btn-primary"
                            v-else-if="this.commessa.closed">Scarica PDF</a>
                          <a @click.prevent="this.excelCommessa" class="btn btn-primary ms-1">Scarica Excel</a>
                        </div>
                        
                        <div class="col-6 d-flex justify-content-end">
                            <a @click.prevent="this.closeCommessa" class="btn btn-primary" v-if="
                                !this.commessa.closed &&
                                this.user.roles !== 'operatore' &&
                                this.tubes.length > 0
                              ">Chiudi commessa</a>
                            <span v-else-if="this.commessa.closed" class="text-danger"><b>Commessa chiusa</b></span>

                            <a @click.prevent="this.openCommessa" class="btn btn-primary ms-2" v-if="
                                this.commessa.closed &&
                                this.user.roles == 'superadmin'">Apri commessa</a>
                        </div>

                        <div class="col-12">
                          <div class="form-check form-switch mt-2">
                            <vee-field
                              class="form-check-input"
                              type="checkbox"
                              id="enabled"
                              name="enabled"
                              :checked="this.commessa.enabledcolor"
                              v-model="this.commessa.enabledcolor"
                              :value="true"
                              :unchecked-value="false"
                            />

                            <label class="form-check-label" :for="this.commessa.enabledcolor">
                              Abilita Colori PDF
                            </label>
                          </div>
                          
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <label for="number" class="form-control-label">Numero</label>
                          <vee-field type="text" class="form-control" name="number" id="number" placeholder="Numero"
                            v-model="this.commessa.number" :value="this.commessa.number"
                            :disabled="this.disabledField" />
                          <error-message class="text-danger text-xs" name="number" />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <label for="client_name" class="form-control-label">Cliente</label>
                          <vee-field type="text" class="form-control" name="client_name" id="client_name"
                            placeholder="Cliente" v-model="this.commessa.client_name" :value="this.commessa.client_name"
                            :disabled="this.disabledField" />
                          <error-message class="text-danger text-xs" name="client_name" />
                        </div>
                        <div class="col-md-4">
                          <label for="order_number" class="form-control-label">Ordine n°</label>
                          <vee-field type="text" class="form-control" name="order_number" id="order_number"
                            placeholder="Ordine n°" v-model="this.commessa.order_number"
                            :value="this.commessa.order_number" :disabled="this.disabledField" />
                          <error-message class="text-danger text-xs" name="order_number" />
                        </div>
                        <div class="col-md-4">
                          <label for="referent" class="form-control-label">Ref.</label>
                          <vee-field type="text" class="form-control" name="referent" id="referent" placeholder="Ref."
                            v-model="this.commessa.referent" :value="this.commessa.referent"
                            :disabled="this.disabledField" />
                          <error-message class="text-danger text-xs" name="referent" />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <label for="inspection_operator" class="form-control-label">Operatore</label>
                          <vee-field type="text" class="form-control" name="inspection_operator"
                            id="inspection_operator" placeholder="Operatore" v-model="this.commessa.inspection_operator"
                            :value="this.commessa.inspection_operator" :disabled="this.disabledField" />
                        </div>
                        <div class="col-md-4">
                          <label for="customer_inspector" class="form-control-label">Ispettore Cliente</label>
                          <vee-field type="text" class="form-control" name="customer_inspector" id="customer_inspector"
                            placeholder="Ispettore Cliente" v-model="this.commessa.customer_inspector"
                            :value="this.commessa.customer_inspector" :disabled="this.disabledField" />
                        </div>
                        <div class="col-md-4">
                          <label for="quality_control" class="form-control-label">Controllo Qualità</label>
                          <vee-field type="text" class="form-control" name="quality_control" id="quality_control"
                            placeholder="Controllo qualità" v-model="this.commessa.quality_control"
                            :value="this.commessa.quality_control" :disabled="this.disabledField" />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <label class="form-control-label">Note interne</label>
                          <textarea v-model="this.commessa.note" class="form-control" name="note" id="note"
                            placeholder="Note" :disabled="this.disabledField"></textarea>
                        </div>
                      </div>
                      <div class="row mt-4">
                        <h4>Prova idraulica</h4>
                        <div v-for="field in this.commessa.hydraulics" :key="field.slug" class="col-md-6">
                          <label class="form-control-label">
                            {{ field.description }}
                          </label>
                          <vee-field type="text" class="form-control" :name="field.slug" :id="field.slug"
                            :placeholder="field.description" v-model="field.value" :value="field.value"
                            :disabled="this.disabledField" />
                        </div>
                      </div>
                      <div class="d-flex justify-content-between" v-if="!this.disabledField">
                        <argon-button size="sm" class="mt-4" variant="gradient" color="success">Salva
                        </argon-button>
                        <router-link to="/" class="btn btn-sm btn-dark float-right mt-4 mb-0 d-none d-lg-block">
                          Annulla
                        </router-link>
                      </div>
                    </div>
                  </div>
                </vee-form>
              </div>
              <div class="tab-pane" id="lista-tubi" role="tabpanel" aria-labelledby="profile-tab"
                :class="{ active: this.tab === 2 }">
                <list-tubes :tubes="this.storeTube.getTubeList()" :updateObjects="this.updateObjects" />
              </div>
              <div class="tab-pane" id="tubo" role="tabpanel" aria-labelledby="profile-tab"
                :class="{ active: this.tab === 3 }">
                <tube-form :tube_prop="this.storeTube.getTube()" :updateObjects="this.updateObjects"></tube-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import router from "@/router";
import ArgonAlert from "@/components/ArgonAlert.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ListTubes from "@/views/tube/tubeList.vue";
import TubeForm from "@/views/tube/tubeForm.vue";
import DashboardLink from "@/views/dashboard/DashboardLink.vue";
import { TubeModel } from "@/models/tube/tube.model";
import useCommessaStore from "@/store/commessa.store";
import useTubeStore from "@/store/tube.store";

import { mapActions } from "pinia";
import { getItem } from "@/utility/localStorageControl";
import { ErrorMessage } from "vee-validate";

export default {
  name: "commessa-add",
  data() {
    return {
      tab: 2,
      headSchema: {
        number: "required",
        client_name: "required",
        order_number: "required",
        referent: "required",
      },
      show_alert: false,
      show_success: false,
      alert_msg: "",
      commessa: {
        client_name: "",
        number: "",
        order_number: "",
        referent: "",
        inspection_operator: "",
        customer_inspector: "",
        quality_control: "",
        hydraulics: {},
        tubes: [],
        note: "",
      },
      hydraulic_list: {},
      idCommessa: "",
      tube: new TubeModel(),
      tubes: [],
      storeCommessa: useCommessaStore(),
      storeTube: useTubeStore(),
      user: {},
      disabledField: false,
    };
  },
  watch: {
    tube(newValue) {
      this.tube = newValue;
      this.tab = 3;
    },
  },
  async created() {
    this.user = getItem("user");
    if (this.user.roles === "operatore") {
      this.disabledField = true;
    }
    this.idCommessa = this.$route.params.id;
    await this.getHydraulicList();

    if (this.idCommessa === undefined) {
      const hydraulics = [];
      this.hydraulic_list.forEach(function (h_item) {
        const elem = {};
        elem.slug = h_item.slug;
        elem.description = h_item.description;
        elem.value = "";
        hydraulics.push(elem);
      });
      this.commessa.hydraulics = hydraulics;
    }

    await this.getCommessa();
  },
  components: {
    ErrorMessage,
    TubeForm,
    ListTubes,
    DashboardLink,
    ArgonButton,
    ArgonAlert,
  },
  methods: {
    ...mapActions(useCommessaStore, ["setCommessa", "setCommessaList"]),
    ...mapActions(useTubeStore, ["setTubeList"]),
    router() {
      return router;
    },

    async getHydraulicList() {
      try {
        const response = await axios.get(this.$endpoint + "/hydraulics/list", {
          headers: {
            authorization: process.env.VUE_APP_APY_KEY,
          },
        });

        if (response.data.status === "OK") {
          this.hydraulic_list = JSON.parse(response.data.data);
        }
        return [];
      } catch (e) {
        console.log(e.toString());
      }
    },
    async getCommessa() {
      try {
        if (this.idCommessa) {
          const response = await axios.get(
            this.$endpoint + "/commessa/show/" + this.idCommessa,
            {
              headers: {
                authorization: process.env.VUE_APP_APY_KEY,
              },
            }
          );
          if (response.data.status === "OK") {
            this.commessa = response.data.commessa;
            await this.storeCommessa.setCommessa(this.commessa);
            await this.storeTube.setTubeList(await this.getTubesByCommessa());
            this.tubes = await this.storeTube.getTubeList();
          }
        }
      } catch (e) {
        console.log(e.toString());
      }
    },
    async getTubesByCommessa() {
      const response = await axios.get(
        this.$endpoint + "/commessa/tubes/" + this.idCommessa,
        {
          headers: {
            authorization: process.env.VUE_APP_APY_KEY,
          },
        }
      );
      return response.data.data;
    },
    async saveCommessa(values) {
      const hydraulics = [];
      this.hydraulic_list.forEach(function (h_item) {
        const slug = h_item.slug;
        const elem = {};
        elem.slug = h_item.slug;
        elem.description = h_item.description;
        if (values[slug]) {
          elem.value = values[slug];
        } else {
          elem.value = "";
        }
        hydraulics.push(elem);
      });

      try {
        this.commessa.hydraulics = hydraulics;
        this.commessa.userId = this.user._id;
        const formData = new FormData();
        formData.append("commessa", JSON.stringify(this.commessa));
        formData.append("count_tube", 0);

        const response = await axios.post(
          this.$endpoint + "/commessa/add",
          formData,
          {
            params: { id: this.commessa._id },
            headers: {
              "Content-Type": "multipart/form-data",
              authorization: process.env.VUE_APP_APY_KEY,
            },
          }
        );

        if (response.data.status === "OK") {
          this.show_success = true;
          this.alert_msg = "Commessa salvata con successo";
        } else {
          this.show_alert = true;
          this.alert_msg = "Errore nel salvataggio";
        }
      } catch (e) {
        this.show_alert = true;
        this.alert_msg = "Errore nel salvataggio";
        console.log(e.toString());
      }
    },
    updateObjects(tube, tabNumber) {
      this.tube = tube;
      this.tab = tabNumber;
      this.storeTube.setTube(this.tube);
    },
    async pdfCommessa() {
      const response = await axios.get(
        this.$endpoint + "/commessa/pdf/" + this.commessa._id,
        {
          responseType: "blob",
          headers: {
            authorization: process.env.VUE_APP_APY_KEY,
          },
        }
      );
      // Converti l'Array Buffer in un Blob
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      // Crea un URL oggetto dal Blob
      const pdfURL = URL.createObjectURL(pdfBlob);
      // Apri una nuova scheda del browser con l'URL del PDF
      window.open(pdfURL, "_blank");
    },
    async closeCommessa() {
      try {
        if (this.idCommessa) {
          const response = await axios.get(
            this.$endpoint + "/commessa/close/" + this.idCommessa,
            {
              headers: {
                authorization: process.env.VUE_APP_APY_KEY,
              },
            }
          );

          if (response.data.status === "OK") {
            this.commessa = response.data.commessa;
            this.show_success = true;
            this.show_alert = false;
            this.alert_msg = "Commessa chiusa con successo";
          } else {
            this.show_alert = true;
            this.alert_msg = response.data.message;
          }
        }
      } catch (e) {
        console.log(e.toString());
      }
    },
    async openCommessa() {
      try {
        if (this.idCommessa) {
          const response = await axios.get(
            this.$endpoint + "/commessa/open/" + this.idCommessa,
            {
              headers: {
                authorization: process.env.VUE_APP_APY_KEY,
              },
            }
          );

          if (response.data.status === "OK") {
            this.commessa = response.data.commessa;
            this.show_success = true;
            this.show_alert = false;
            this.alert_msg = "Commessa aperta con successo";
          } else {
            this.show_alert = true;
            this.alert_msg = response.data.message;
          }
        }
      } catch (e) {
        console.log(e.toString());
      }
    },
    async pdfDownloadCommessa() {
      const response = await axios.get(
        this.$endpoint + "/commessa/download/pdf/" + this.commessa._id,
        {
          responseType: "blob",
          headers: {
            authorization: process.env.VUE_APP_APY_KEY,
          },
        }
      );
      // Converti l'Array Buffer in un Blob
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      // Crea un URL oggetto dal Blob
      const pdfURL = URL.createObjectURL(pdfBlob);
      // Apri una nuova scheda del browser con l'URL del PDF
      window.open(pdfURL, "_blank");
    },
    async excelCommessa() {
      const response = await axios.get(
        this.$endpoint + "/commessa/excel/" + this.commessa._id,
        {
          responseType: "blob",
          headers: {
            authorization: process.env.VUE_APP_APY_KEY,
          },
        }
      );

      // Converti l'Array Buffer in un Blob
      const excelBlob = new Blob([response.data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      // Crea un URL oggetto dal Blob
      const excelURL = URL.createObjectURL(excelBlob);
      // Crea un elemento <a> e impostalo per il download
      const link = document.createElement("a");
      link.href = excelURL;
      const fileName = `commessa-${this.commessa.number}.xlsx`;
      link.setAttribute("download", fileName);

      // Aggiungi l'elemento <a> al DOM
      document.body.appendChild(link);

      // Clicca sul link per avviare il download
      link.click();

      // Rimuovi l'elemento <a> dal DOM
      document.body.removeChild(link);
    },
  },
};
</script>
